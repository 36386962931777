import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { AppLink } from '~/components/AppLink'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { BodyItem } from '~/modules/symbolQuote/square/BodyItem'
import Col_FaviconTitle from '~/pages/heineken_template/_col/col_FaviconTitle'
import { store } from '~/pages/heineken_template/_private/store'
import styleds from '~/pages/sungop/_private/styleds'
import { sungop_store } from './sungop_store'
import { SymbolBidAsk } from './component/SymbolBidAsk'
import { AppLink2 } from '~/components/AppLink2'

const indexs = ['TX-1', 'MTX-1', 'TSEA', 'TSE13', 'TSE17']
const stocks = ['2330', '2317', '2454', '2412', '6505']

export const Sungop_PageMenu = memo<ReactProps>(function PageMenu(props) {
  const state = useSnapshot(sungop_store)
  const symbolGroup = () => {
    if (state.menuSymbolMode === 'index') {
      return indexs
    }
    if (state.menuSymbolMode === 'stock') {
      return stocks
    }
  }
  return (
    <styleds.Sidebar>
      <Col_FaviconTitle.Display />

      <styleds.PageMenuBox>
        <AppLink2 href='/heineken_template'>吳極指標</AppLink2>
        <AppLink2 href='/heineken_template/stock-monitor-table'>龍頭監控分數</AppLink2>
        <AppLink2 href='/heineken_template/daily-chips'>每日籌碼</AppLink2>
        <AppLink2 href='/heineken_template/option-chips'>選擇權籌碼</AppLink2>
        <AppLink2 href='/heineken_template/option-state'>選擇權未平倉</AppLink2>
      </styleds.PageMenuBox>

      <styleds.PageMenuSymbolList>
        <div
          css={css`
            ${fill_horizontal_all_center}
            height:30px;
            gap: 4px;
          `}
        >
          <styleds.PageMenuButton
            active={state.menuSymbolMode === 'index'}
            onClick={() => (sungop_store.menuSymbolMode = 'index')}
          >
            指數
          </styleds.PageMenuButton>
          <styleds.PageMenuButton
            active={state.menuSymbolMode === 'stock'}
            onClick={() => (sungop_store.menuSymbolMode = 'stock')}
          >
            股票
          </styleds.PageMenuButton>
        </div>
        {symbolGroup()?.map(symbol => {
          return (
            <SymbolBidAsk
              symbol={symbol}
              key={symbol}
            ></SymbolBidAsk>
          )
        })}
      </styleds.PageMenuSymbolList>
    </styleds.Sidebar>
  )
})
