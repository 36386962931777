import { css } from '@emotion/react'
import { memo } from 'react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { useUnifyTableResource } from '~/modules/chips/useChipResource'
import dayAPI from '~/utils/dayAPI'
import { ChipOverViewBoard } from './component/ChipOverViewBoard'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useChipScore } from '~/modules/screener/containers/useStockScreenerResource'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { useSnapshot } from 'valtio'

export const Sungop_DailyChips = memo<ReactProps>(function Sungop_DailyChips() {
  //日期轉換
  const todayDate = new Date()
  const startUnix = new Date().setDate(todayDate.getDate() - 30)
  const startDate = new Date(startUnix)
  const todayURL = Math.floor(todayDate.setHours(15, 5, 0) / 1000)
  const startURL = Math.floor(startDate.setHours(15, 0, 0) / 1000)

  const chips = useUnifyTableResource(startURL, todayURL)

  const day0 = useSnapshot(staticStore).tradedDate.day0
  const chipScore = useChipScore({ date: day0.format('YYYY-MM-DD') })?.score

  //當日日期
  const chipsDate = chips?.t[chips?.t.length - 1] ?? todayURL
  const chipsDateToString = dayAPI(chipsDate * 1000).format('YYYY/MM/DD')

  //陣列長度
  const dataDate = chips?.t
  //外資系列
  const foreignerTWSE = chips?.foreigner_twse
  const foreignerTPEX = chips?.foreigner_tpex
  const foreignerTXF = chips?.foreigner_txf
  const foreignerOPT = chips?.foreigner_opt

  //自營商系列
  const dealerTWSE = chips?.dealer_twse
  const dealerTPEX = chips?.dealer_tpex
  const dealerTXF = chips?.dealer_txf
  const dealerOPT = chips?.dealer_opt

  //投信系列
  const trustTWSE = chips?.trust_twse
  const trustTPEX = chips?.trust_tpex
  const trustOPT = chips?.trust_opt

  //大額交易人(台指期貨)系列
  const largeTop5TXF = chips?.large_trader_futures_top5
  const largeLast5TXF = chips?.large_trader_futures_last5
  const largeTop10TXF = chips?.large_trader_futures_top10
  //大額交易人(選擇權留倉)系列
  const largeTop5OPT = chips?.large_trader_options_top5
  const largeLast5OPT = chips?.large_trader_options_last5
  const largeTop10OPT = chips?.large_trader_options_top10

  //散戶小型台指系列
  const retailMTF = chips?.retail_oi

  const twseArray = [
    {
      title: '外資',
      unit: '億',
      date: dataDate,
      value: foreignerTWSE?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '億',
      date: dataDate,
      value: dealerTWSE?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '億',
      date: dataDate,
      value: trustTWSE?.trading_value_diff,
      dataLenght: 10,
    },
  ]

  const tpexArray = [
    {
      title: '外資',
      unit: '億',
      date: dataDate,
      value: foreignerTPEX?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '億',
      date: dataDate,
      value: dealerTPEX?.trading_value_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '億',
      date: dataDate,
      value: trustTPEX?.trading_value_diff,
      dataLenght: 10,
    },
  ]

  const TXFArray = [
    {
      title: '外資',
      unit: '口',
      date: dataDate,
      value: foreignerTXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '口',
      date: dataDate,
      value: dealerTXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '散戶(小台)',
      unit: '口',
      date: dataDate,
      value: retailMTF?.oi_diff,
      dataLenght: 10,
    },
  ]

  /** 買賣權分計 */
  const optArray = [
    {
      title: '外資',
      unit: '口',
      date: dataDate,
      value: foreignerOPT?.oi_volume_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '口',
      date: dataDate,
      value: dealerOPT?.oi_volume_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '口',
      date: dataDate,
      value: trustOPT?.oi_volume_diff,
      dataLenght: 10,
    },
  ]

  const optDiffVolArray = [
    {
      title: '外資',
      unit: '口',
      date: dataDate,
      value: foreignerOPT?.base_oi_volume_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '口',
      date: dataDate,
      value: dealerOPT?.base_oi_volume_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '口',
      date: dataDate,
      value: trustOPT?.base_oi_volume_diff,
      dataLenght: 10,
    },
  ]
  const optDiffValArray = [
    {
      title: '外資',
      unit: '千元',
      date: dataDate,
      value: foreignerOPT?.base_oi_value_diff,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '千元',
      date: dataDate,
      value: dealerOPT?.base_oi_value_diff,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '千元',
      date: dataDate,
      value: trustOPT?.base_oi_value_diff,
      dataLenght: 10,
    },
  ]

  const baseNetValOptArray = [
    {
      title: '外資',
      unit: '千元',
      date: dataDate,
      value: foreignerOPT?.base_net_oi_value,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '千元',
      date: dataDate,
      value: dealerOPT?.base_net_oi_value,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '千元',
      date: dataDate,
      value: trustOPT?.base_net_oi_value,
      dataLenght: 10,
    },
  ]

  const baseNetVolOptArray = [
    {
      title: '外資',
      unit: '口',
      date: dataDate,
      value: foreignerOPT?.base_net_oi_volume,
      dataLenght: 10,
    },
    {
      title: '自營',
      unit: '口',
      date: dataDate,
      value: dealerOPT?.base_net_oi_volume,
      dataLenght: 10,
    },
    {
      title: '投信',
      unit: '口',
      date: dataDate,
      value: trustOPT?.base_net_oi_volume,
      dataLenght: 10,
    },
  ]
  const top5Array = [
    {
      title: '前五大',
      unit: '口',
      date: dataDate,
      value: largeTop5TXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '前十大',
      unit: '口',
      date: dataDate,
      value: largeTop10TXF?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '後五大',
      unit: '口',
      date: dataDate,
      value: largeLast5TXF?.oi_diff,
      dataLenght: 10,
    },
  ]

  const top5OpArray = [
    {
      title: '前五大',
      unit: '口',
      date: dataDate,
      value: largeTop5OPT?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '前十大',
      unit: '口',
      date: dataDate,
      value: largeTop10OPT?.oi_diff,
      dataLenght: 10,
    },
    {
      title: '後五大',
      unit: '口',
      date: dataDate,
      value: largeLast5OPT?.oi_diff,
      dataLenght: 10,
    },
  ]

  return (
    <div
      css={css`
        padding: 16px;
        background-color: #181a1f;
        gap: 8px;
        ${scrollbar2Css};
      `}
    >
      更新日期:{chipsDateToString}
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0 auto;
          gap: 16px;
          justify-content: center;
        `}
      >
        <ChipsValueCard
          value={[chipScore ?? 0]}
          unit='分'
        >
          籌碼分數
        </ChipsValueCard>
        <ChipsValueCard
          value={foreignerTWSE?.trading_value_diff.slice(-1) ?? []}
          decimal={1e8}
          unit='億'
        >
          外資現貨
        </ChipsValueCard>
        <ChipsValueCard
          value={foreignerTXF?.net_oi_volume.slice(-1) ?? []}
          unit='口'
        >
          外資期貨
        </ChipsValueCard>
        <ChipsValueCard
          value={largeTop5TXF?.oi.slice(-1) ?? []}
          unit='口'
        >
          十大交易人期貨
        </ChipsValueCard>
        <ChipsValueCard
          value={dealerOPT?.base_net_oi_value.slice(-1) ?? []}
          unit='千元'
        >
          自營商選權
        </ChipsValueCard>
        <ChipsValueCard
          value={foreignerOPT?.base_net_oi_value.slice(-1) ?? []}
          unit='千元'
        >
          外資選擇權
        </ChipsValueCard>
      </div>
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0 auto;
          gap: 16px;
          justify-content: center;
        `}
      >
        <ChipOverViewBoard data={twseArray}>現貨買賣超(上市)</ChipOverViewBoard>
        <ChipOverViewBoard data={tpexArray}>現貨買賣超(上櫃)</ChipOverViewBoard>
        <ChipOverViewBoard data={TXFArray}>台指期貨(日增減口數)</ChipOverViewBoard>
        <ChipOverViewBoard data={top5Array}>期貨大額交易人(增減口數)</ChipOverViewBoard>
        <ChipOverViewBoard
          data={optDiffVolArray}
          bgColor='#222831'
        >
          台指選擇權(增減口數)
        </ChipOverViewBoard>
        <ChipOverViewBoard
          data={optDiffValArray}
          bgColor='#1d283a'
        >
          台指選擇權(增減金額)
        </ChipOverViewBoard>
        <ChipOverViewBoard
          data={baseNetValOptArray}
          bgColor='#1d283a'
        >
          台指選擇權(未平金額)
        </ChipOverViewBoard>
        <ChipOverViewBoard
          data={baseNetVolOptArray}
          bgColor='#1d283a'
        >
          台指選擇權(未平口數)
        </ChipOverViewBoard>
        <ChipOverViewBoard
          data={top5OpArray}
          bgColor='#1d283a'
        >
          選擇權大額交易人(增減口數)
        </ChipOverViewBoard>
        <ChipOverViewBoard
          data={optArray}
          bgColor='#1d283a'
        >
          台指選擇權(買賣權分計)
        </ChipOverViewBoard>
      </div>
    </div>
  )
})

export const ChipsValueCard = memo<ReactProps<{ value: number[]; unit: string; decimal?: number }>>(
  function ChipsValueCard(props) {
    const decimal = props.decimal ?? 1
    const value = props.value[0] / decimal

    const displayPosition = value > 0 ? '偏多' : value < 0 ? '偏空' : '觀察'
    return (
      <div
        css={css`
          ${fill_vertical_all_center};
          position: relative;
          width: 172px;
          height: 64px;
          border-radius: 8px;
          background-color: #232530;
          gap: 4px;
          font-size: 14px;
          cursor: pointer;
          padding: 2px;
          &:hover {
            background-color: #2e303caa;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            ${fill_vertical_all_center};
            width: 48px;
            height: 28px;
            background: linear-gradient(
              45deg,
              ${value > 0 ? '#aa5533' : value < 0 ? '#44aa44' : '#2e303c'},
              ${value > 0 ? '#aa3333' : value < 0 ? '#55aa33' : '#2e303c'}
            );
            top: -14px;
            margin-left: 80%;
            border-radius: 16px;
          `}
        >
          {displayPosition}
        </div>
        <span>{props.children}</span>
        <span
          css={css`
            background-color: #181a1faa;
            border-radius: 4px;
            padding: 0px 4px;
          `}
        >
          {value.toFixed(0)}
          {props.unit}
        </span>
      </div>
    )
  },
)
