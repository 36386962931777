import { css } from '@emotion/react'
import { memo } from 'react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import Futuresai_Row2 from '~/pages/futuresai/opbs/futuresai_Row2'
import Futuresai_Col1 from '~/pages/futuresai/opbs/futuresai_Col1'

export const sungop_OptionChips = memo<ReactProps>(function sungop_OptionChips() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        padding: 4px;
        background-color: #181a1f;
        color: #fafafa !important;
        ${scrollbar2Css};

        & > text {
          fill: #fafafa;
        }
      `}
    >
      <Futuresai_Row2 />
      <Futuresai_Col1 />
    </div>
  )
})
