import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { memo } from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Cell,
  Tooltip,
  XAxis,
  ReferenceLine,
  CartesianGrid,
  YAxis,
  XAxisProps,
  YAxisProps,
} from 'recharts-new'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { toReadableUnitChinese } from '~/utils/representChineseNumberFormat'

/** 近N日籌碼每日增減圖表 (rechart圖表) */
export const ChipsChart = memo<
  ReactProps<{
    data: { value: number; date: string }[]
  }>
>(function ChipsChart(props) {
  //為了增加判讀容易度 將座標高低都一樣的高度 0軸維持中間
  const maxValue = Math.max(...props.data.map(s => Math.abs(s.value))) * 1.05
  const domainRange = [maxValue, -maxValue]
  const priceRange = [maxValue, -maxValue]

  const fontSize = 10
  const xAxisProps: XAxisProps = {
    tick: { fontSize: fontSize, fill: '#ffffff' },
  }
  const yAxisProps: YAxisProps = { tick: { fontSize: fontSize, fill: '#ffffff' } }

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <BarChart
        data={props.data}
        margin={{
          bottom: -12,
          left: 20,
        }}
      >
        <CartesianGrid
          strokeLinejoin='round'
          stroke='#dddddd'
          strokeOpacity={0}
          strokeDasharray='2 2'
          //fill='#2d303e'
        />
        {/* <Tooltip formatter={(value: number) => toReadableUnitChinese(value)} /> */}
        <Tooltip content={<CustomizedTooltip />} />
        <Bar
          dataKey='value'
          name={'增減'}
        >
          {props.data.map((datum, index) => (
            <Cell
              key={index}
              fill={datum.value >= 0 ? '#d32f2f' : '#4caf50'}
            />
          ))}
        </Bar>
        <ReferenceLine
          y={0}
          stroke='#aaaaaa'
        />
        <XAxis
          dataKey='date'
          name={'日期'}
          axisLine={false}
          tickLine={false}
          {...xAxisProps}
        />
        <YAxis
          domain={domainRange}
          ticks={priceRange}
          hide={true}
          fill='#ffffff'
          {...yAxisProps}
        />
      </BarChart>
    </ResponsiveContainer>
  )
})

//客製化的游標資料浮動視窗
const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
  if (!payload || (payload && payload.length < 1)) return null

  const datum = payload[0].payload
  const date = datum.date
  const value = datum.value

  return (
    <ToolTip
      date={date}
      value={value}
    />
  )
}

export const ToolTip = memo<
  ReactProps<{
    date: string
    value: number
  }>
>(function ToolTip(props) {
  const theme = useThemeStore(s => s.theme)

  //這邊比較不會客製化 先寫死 日後有需要再改就好
  const changeColor = props.value >= 0 ? '#d32f2f' : '#4caf50'

  return (
    <classes.container className={theme}>
      <div>{props.date}</div>
      <div
        css={css`
          color: ${changeColor};
        `}
      >
        {props.value}
      </div>
    </classes.container>
  )
})

const classes = {
  container: styled.div`
    padding: 8px;
    opacity: 0.9;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
    &.dark {
      border: 1px solid #888888;
      background-color: #252525;
      color: #fafafa;
    }
    &.light {
      border: 1px solid #b4b4b4;
      background-color: #fafafa;
      color: #252525;
    }
  `,
}
