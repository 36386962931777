import { css } from '@emotion/react'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '~/pages/heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  flex,
  jc,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'
import usePriceDiffValue from '~/modules/SDK/priceDiffChart/usePriceDiffValue'
import PriceDiffChart from '~/modules/SDK/priceDiffChart'
import { useAsyncFn } from 'react-use'
import { apirc } from '~/configs/apirc'
import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import useMedia from '~/hooks/useMedia'
import { useWeightedStockRatio } from './component/WeightedStockRatioList'
import { sum } from 'lodash'
import useSWR from 'swr'
import { useDatePick } from '~/modules/screener/useDatePick'
import { useChipScore } from '~/modules/screener/containers/useStockScreenerResource'
import { priceDiffStore } from '~/modules/SDK/priceDiffChart/priceDiffStore'
import { sungop_strategiesGroup } from './sungop_initStrategies'
import { Select } from '@mantine/core'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { sungop_store } from './sungop_store'

const errorEmptyArrayFetcher = async (url: string) => {
  const res = await fetch(url)

  return !res.ok ? [] : res.json()
}

export type VixTradeInfo = {
  /** E.g. `'2022-01-01'` */
  Date: string
  avg5daysVix: number
  avg20daysVix: number
  avg60daysVix: number
  currentVix: number
  max5daysVix: number
  max20daysVix: number
  max60daysVix: number
  min5daysVix: number
  min20daysVix: number
  min60daysVix: number
}

export const useDefaultVixData = (date: string) => {
  const url =
    `https://trading-event-handler-ytvffgctuq-de.a.run.app/get_tradeinfo?type=vix&date=` + date

  const res = useSWR<VixTradeInfo[]>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data?.[0],
  }
}

export const Sungop_Sidebar = memo<ReactProps>(function Sidebar() {
  const { isPc, isPhone } = useMedia()
  const charting = useSnapshot(store.charting)
  const priceDiffState = useSnapshot(priceDiffStore)

  const { diffValue } = usePriceDiffValue()

  //Vix--------------------------------------------
  const [vixValue, fetchVixValue] = useAsyncFn(async () => {
    const values = await apirc.marketDataAPI.vixInfo.fetch()
    return values.value
  }, [])

  //大戶--------------------------------------------
  const [bsValue, fetchBsValue] = useAsyncFn(async () => {
    const values = await apirc.marketDataAPI.dashboardData.fetch({
      target: 'dashboard_bs_indicator',
    })
    return getIndicatorAngular(values.value) * 100
  }, [])

  //sung op
  const tradeDateArray = useSnapshot(staticStore).tradedDate.days
  const day0 = useSnapshot(staticStore).tradedDate.day0
  const chipDate = useSnapshot(sungop_store).dataDate
  const chipScore = useChipScore({ date: chipDate })?.score

  //權值股--------------------------------------------
  const stockData = useWeightedStockRatio()
  const allScore = sum(stockData.map(s => s.contribution))

  //MC Vix
  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const vix = useDefaultVixData(intraday.format('YYYY-MM-DD'))
  const defaultVix = vix.data?.currentVix

  useEffect(() => {
    sungop_store.dataDate = day0.format('YYYY-MM-DD')
  }, [day0])

  useIntervalNow(() => {
    fetchVixValue()
    fetchBsValue()
  }, 1000)

  return (
    <styleds.Container
      css={css`
        ${isPc || isPhone ? flex.wrap.allCenter : flex.wrap.crossCenter};
      `}
    >
      <styleds.ButtonGroup>
        {sungop_strategiesGroup.main.map(config => {
          const isActive = charting.strategySelected.displayName === config.displayName

          return (
            <styleds.IndicatorsButton
              key={config.displayName}
              selected={isActive}
              onClick={event => {
                store.charting.setStrategy(config)
                store.charting.updateFromStrategyState()
              }}
            >
              {config.displayName}
            </styleds.IndicatorsButton>
          )
        })}
      </styleds.ButtonGroup>
      {!isPhone && (
        <div
          css={css`
            ${fill_vertical_all_center};
            width: 280px;
            height: 160px;
            border-radius: 7px;
            background-color: #2d303eaa;
            padding: 8px;
          `}
        >
          <TitleSymbolQuote.Default symbol={charting.symbol} />
          <div
            css={css`
              width: 248px;
              height: 132px;
            `}
          >
            <IntradayTrendChart
              symbol={charting.symbol}
              ticksSize={12}
              ticksHeight={20}
              priceTicksMargin={-10}
              priceTicksSize={11}
            />
          </div>
        </div>
      )}
      <styleds.SettlementContainer
        css={css`
          padding: 0px 16px 0px 0px;
          height: 116px;
        `}
      >
        <PriceDiffChart
          symbol1='TXAM-1'
          symbol2='TSEA'
        />
      </styleds.SettlementContainer>
      <PricDiffValueCard
        value={diffValue}
        maxValue={priceDiffState.max}
        minValue={priceDiffState.min}
        text={'期現價差'}
      />

      <ProgressbarCard
        value={getIndicatorAngular(allScore) * 100}
        text={'現貨龍頭分數'}
        defaultColor={false}
      />
      <ProgressbarCard
        value={bsValue.value ?? 0}
        text={'期貨風暴力差'}
        defaultColor={false}
      />
      <ValueCard
        value={defaultVix ?? 0}
        text={'波動率VIX'}
      />
      <ChipValueCard
        value={chipScore ?? 0}
        text={'日線籌碼分數'}
      >
        <Select
          css={css`
            .mantine-58jpum {
              background-color: #272b37;
              padding-right: 0;
              padding-left: 16px;
            }
            .mantine-10iuxpt {
              padding-right: 0;
            }
          `}
          rightSection={<div></div>}
          defaultValue={tradeDateArray.map(s => s.format('YYYY-MM-DD'))[0]}
          data={tradeDateArray.map(s => s.format('YYYY-MM-DD'))}
          onChange={(date: string) => {
            sungop_store.dataDate = date
          }}
        />
      </ChipValueCard>
    </styleds.Container>
  )
})

export const ValueCard = memo<ReactProps<{ text: string; value: number }>>(function ValueCard(
  props,
) {
  return (
    <styleds.Card>
      <styleds.Progressbar value={props.value}>{props.value?.toFixed(2)}</styleds.Progressbar>
      <styleds.ProgressText>{props.text}</styleds.ProgressText>
    </styleds.Card>
  )
})

export const ChipValueCard = memo<ReactProps<{ text: string; value: number }>>(
  function ChipValueCard(props) {
    return (
      <styleds.Card
        css={css`
          grid-template-rows: 50% 20% 30%;
        `}
      >
        <styleds.Progressbar
          value={props.value}
          css={css`
            margin-top: 12px;
          `}
        >
          {props.value?.toFixed(2)}
        </styleds.Progressbar>
        <div
          css={css`
            width: 116px;
          `}
        >
          {props.children}
        </div>

        <styleds.ProgressText>{props.text}</styleds.ProgressText>
      </styleds.Card>
    )
  },
)

export const PricDiffValueCard = memo<
  ReactProps<{ text: string; value: number; maxValue: number; minValue: number }>
>(function PricDiffValueCard(props) {
  const symbol = props.value === 0 ? '' : props.value > 0 ? '+' : ''
  return (
    <div
      css={css`
        ${fill_horizontal_all_center};
        ${jc.spaceEvenly};
        width: 280px;
        height: 140px;
        gap: 16px;
      `}
    >
      <styleds.Card>
        <ValueCard
          value={Math.round((props.value * 10) / 10) ?? 0}
          text={props.text}
        />
      </styleds.Card>
      <styleds.Card
        css={css`
          grid-template-rows: 35% 35% 30%;
          & > div {
            ${fill_vertical_all_center};
          }
        `}
      >
        <div
          css={css`
            color: #ff0031;
          `}
        >
          最大值&nbsp;{props.maxValue}
        </div>
        <div
          css={css`
            color: #00ff31;
          `}
        >
          最小值&nbsp;{props.minValue}
        </div>
        <styleds.ProgressText>{props.text}</styleds.ProgressText>
      </styleds.Card>
    </div>
  )
})

export const ProgressbarCard = memo<
  ReactProps<{ text: string; value: number; defaultColor?: boolean }>
>(function Progressbar(props) {
  const defaultMode = props.defaultColor ?? true
  const color = props.value >= 0 ? '#E8433C' : '#6fe747'
  const defaultColor = defaultMode === true ? '#72f6f1' : color
  return (
    <styleds.Card>
      <styleds.Progressbar>
        <CircularProgressbar
          value={Math.abs(props.value)}
          text={`${props.value.toFixed(2)}%`}
          strokeWidth={10}
          styles={buildStyles({
            rotation: 0,
            strokeLinecap: 'butt',
            textSize: '20px',
            textColor: '#eeeeee',
            pathColor: defaultColor,
            trailColor: '#444c63',
          })}
        />
      </styleds.Progressbar>
      <styleds.ProgressText>{props.text}</styleds.ProgressText>
    </styleds.Card>
  )
})

export const SettlementCard = memo<ReactProps<{ value: number }>>(function SettlementCard(props) {
  return (
    <styleds.SettlementContainer>
      <styleds.SettlementValue>{props.value}</styleds.SettlementValue>
      <styleds.SettlementTitle>週三預估結算價</styleds.SettlementTitle>
    </styleds.SettlementContainer>
  )
})

const styleds = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    background-color: #181a21;
    gap: 16px;
    padding: 16px;
  `,
  Card: styled.div`
    ${pureGrid};
    width: 132px;
    height: 140px;
    justify-items: center;
    grid-template-rows: 70% 30%;
    background-color: #2c303f;
    border-radius: 7px;
    &:hover {
      background-color: #2e303daa;
    }
  `,
  Progressbar: styled.div<{ value?: number }>`
    ${fill_vertical_all_center};
    padding: 8px;
    font-size: 24px;
    ${options => {
      const value = options?.value ?? 0
      const color = css`
        color: ${value === 0 ? '' : value > 0 ? '#ff0031' : '#00ff31'};
      `

      return css([color])
    }}
  `,
  ProgressText: styled.div`
    ${fill_vertical_all_center};
    font-size: 16px;
  `,

  SettlementContainer: styled.div`
    ${fill_vertical_all_center}
    width: 280px;
    height: 80px;
    background-color: #2c303f;
    border-radius: 7px;
    padding: 8px;
  `,
  SettlementTitle: styled.div`
    ${fill_vertical_all_center};
    padding: 8px;
    font-size: 16px;
  `,
  SettlementValue: styled.div`
    ${fill_vertical_all_center};
    font-size: 24px;
  `,
  ButtonGroup: styled.div`
    ${fill_horizontal_all_center};
    width: 280px;
    height: 40px;
    border-radius: 7px;
    background-color: #262934;
    padding: 8px;
  `,
  IndicatorsButton: styled.div<{ selected: boolean }>`
    ${fill_vertical_all_center};
    width: 132px;
    height: 32px;
    border-radius: 4px;
    color: ${props => (props.selected === true ? '#ffffff' : '#aaaaaa')};
    background-color: ${props => (props.selected === true ? '#2d303e' : null)};
    border: 1px solid ${props => (props.selected === true ? '#fafafa' : '#fafafa00')};
    cursor: pointer;
    transition: 0.3s;
  `,
}
