import { Dayjs } from 'dayjs'
import { proxy } from 'valtio'
import dayAPI from '~/utils/dayAPI'

export const sungop_store = proxy({
  dataDate: '' as string,
  menuSymbolMode: 'index' as 'index' | 'stock',
  sidebarSymbols1: [
    'TX-1',
    'TSEA',
    'OTCA',
    'TSE13',
    'TSE17',
    '2330',
    '2317',
    '2454',
    '6505',
    '2308',
    '2881',
    '2882',
  ],
})
